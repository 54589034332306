import logo from "./images/logo.png";
import hero from "./images/tonguepaw_lg.jpg"
import one from './images/img1.jpeg'
import two from './images/img2.jpeg'
import three from './images/img3.jpeg'
import banner from './images/banner.jpeg'
import asset1 from './images/image-asset.jpeg'
import asset2 from './images/image-asset1.jpeg'
import asset3 from './images/image-asset2.jpeg'
import asset4 from './images/image-asset3.jpeg'
import asset5 from './images/image-asset4.jpeg'
import asset6 from './images/image-asset5.jpeg'
import asset7 from './images/image-asset6.jpeg'
import asset8 from './images/image-asset7.jpeg'
import blog from './images/blog1.jpeg'
import blog2 from './images/blog2.jpeg'
import blog3 from './images/blog3.jpeg'
import waffle from './images/waffle.jpeg'
import './App.css';
import { FaHamburger, FaTelegram } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"


function App() {
  return (
    <div>
      <div className="">

        <nav className="md:flex items-center md:justify-between py-3 md:mx-20 pl-3 md:pl-0 font-semibold text-sm hidden">
          <div className="md:flex hidden items-center space-x-10">
            <img src={logo} alt="" className="w-24 bg-cover" />
            <div className="md:flex hidden space-x-4">
              <h1>ABOUT <span className="ml-3">/</span></h1>
              <h1>MEME <span className="ml-3">/</span></h1>
            </div>
          </div>



          <div className="text-3xl md:flex space-x-3 text-orange-500 hidden">
            <a href="https://t.me/WafflestheCat" className="hover:cursor-pointer">
              <FaTelegram />
            </a>
            <a href="https://x.com/WafflesonSol?t=ZiknWVRGyU_wKfHT2KbEeg&s=09" className="hover:cursor-pointer">
              <FaTwitter />
            </a>


          </div>
        </nav>

        <nav className="md:hidden flex py-4 pl-5 hover:cursor-pointer">
          <FaHamburger />

          <div className="uppercase ml-20">Waffles The Cat</div>
        </nav>

        <section>
          <div className=" pb-4 max-w-[100vw] " style={{ height: "calc(100vh - 120px)" }}>
            <img src={hero} alt="" className="md:h-[100%] h-[100%] md:w-full object-cover" />
          </div>

          <div className="relative md:bottom-[500px] md:pl-20 md:w-[45%] pl-8 bottom-[400px] " >
            <h1 className="md:text-[80px] text-[3rem] font-semibold text-gray-100 text-orange-500">
            "It's a Cat called Waffles!"
            </h1>
            <p className="md:text-[20px]  md:block font-semibold text-gray-100 uppercase">
              
              The cute master of all Cats on Solana"
            </p>
          </div>
        </section>


        <section >
          <h1 className="text-center mb-6 font-bold text-4xl text-orange-500 shadow-xl w-fit rounded-xl mx-auto  p-3">TOKENOMICS</h1>
          <div className="md:grid md:grid-cols-4 md:px-20 w-[70%] gap-4 md:space-y-0 space-y-3 mx-auto">
            <div className="border-orange-500 border p-10 text-center rounded-xl shadow-lg w-full"> 
              <h1 className="font-bold text-3xl text-orange-500 mb-3">SYMBOL</h1>
              <h1 className="font-semibold">$WafflesTheCat</h1>
            </div>
            <div className="border-orange-500 border w-full p-10 text-center rounded-xl shadow-lg"> 
              <h1 className="font-bold text-3xl text-orange-500 mb-3">SUPPLY</h1>
              <h1 className="font-semibold">1,000,000,000</h1>
            </div>
            <div className="border-orange-500 border w-full p-10 text-center rounded-xl shadow-lg"> 
              <h1 className="font-bold text-3xl text-orange-500 mb-3">TAX</h1>
              <h1 className="font-semibold">0/0</h1>
            </div>
            <div className="border-orange-500 border w-full p-10 text-center rounded-xl shadow-lg"> 
              <h1 className="font-bold text-3xl text-orange-500 mb-3">LP</h1>
              <h1 className="font-semibold">BURNED FOREVER</h1>
            </div>


            
          </div>
          <div className="border-orange-500 border  p-10 text-center rounded-xl shadow-lg mx-auto  mt-4 w-[70%]"> 
              <h1 className="font-bold text-3xl text-orange-500 mb-3">TOKEN ADDRESS</h1>
              <h1 className="font-semibold break-words">94oS52MpaSzEo7m1728AkbgPWjGjPTYQcBhYV6eSbwq6</h1>
            </div>
        </section>


      </div>


      {/* <section className="flex md:flex-row flex-col space-y-6 md:space-y-0 mx-auto md:w-fit md:space-x-10 w-[80%] ">

        <div className=" md:w-[400px] flex flex-col" >
          <img src={one} alt="" />
          <button className="font-semibold text-white bg-orange-500 py-3 w-fit px-8 mt-8 mx-auto ">POUCHES</button>
        </div>
        <div className=" md:w-[400px] flex flex-col" >
          <img src={two} alt="" />
          <button className="font-semibold text-white bg-orange-500 py-3 w-fit px-8 mt-8 mx-auto ">PINS</button>
        </div>
        <div className=" md:w-[400px] flex flex-col" >
          <img src={three} alt="" />
          <button className="font-semibold text-white bg-orange-500 py-3 w-fit px-8 mt-8 mx-auto ">PRINTS</button>
        </div>


      </section> */}

      <section className="hover:cursor-pointer">
        <div className="md:w-[88%] w-[80%]  mx-auto mt-10" >
          <h1 className=" text-orange-500 text-2xl mb-3 font-light">Free Emoji !</h1>
          <img src={banner} alt="" />
          <p className="mt-2 text-xs">SEARCH FOR “WAFFLES THE CAT” IN IOS APP STORE FOR FREE DOWNLOAD</p>
        </div>
      </section>

      <section className="md:px-[88px] w-[80%] mx-auto mt-10">
        <h1 className=" text-orange-500 text-2xl mb-3 font-light">Instagram Gallery</h1>
        <div className="grid grid-cols-4 gap-4 ">
          <img src={asset1} alt="" className="hover:cursor-pointer" />
          <img src={waffle} alt="" className="hover:cursor-pointer" />
          <img src={asset3} alt="" className="hover:cursor-pointer" />
          <img src={asset4} alt="" className="hover:cursor-pointer" />
          <img src={asset5} alt="" className="hover:cursor-pointer" />
          <img src={asset6} alt="" className="hover:cursor-pointer" />
          <img src={asset7} alt="" className="hover:cursor-pointer" />
          <img src={asset8} alt="" className="hover:cursor-pointer" />

        </div>
      </section>

      <section className="md:px-[88px] w-[80%] mt-8 mx-auto md:mt-10 md:mb-40">
        <h1 className=" text-orange-500 text-2xl mb-3 font-light">Blogs</h1>
        <h1 className=" text-gray-500 mb-3">Featured</h1>

        <div className="flex md:space-x-10 space-x-5 md:space-y-0 mb-10">
          <div className="md:w-[400px] hover:cursor-pointer">
            <img src={blog} alt="" className="mb-4 h-[350px] object-cover" />
            <h1 className="font-light">Waffles the Cat in Avengers Costumes</h1>
            <h1 className="font-extralight">Waffles the Cat in Avengers Costumes</h1>
            <h1 className="mt-2 text-xs text-gray-500">April 3, 2024</h1>
          </div>
          <div className="md:w-[400px] hover:cursor-pointer">
            <img src={blog2} alt="" className="mb-4 h-[350px] object-cover" />
            <h1 className="font-light">Waffles the Cat in Avengers Costumes</h1>
            <h1 className="font-extralight">Waffles the Cat in Avengers Costumes</h1>
            <h1 className="mt-2 text-xs text-gray-500">April 3, 2024</h1>
          </div>
          <div className="md:w-[400px] hover:cursor-pointer hidden md:block ">
            <img src={blog3} alt="" className="mb-4  object-cover" />
            <h1 className="font-light">Waffles the Cat in Avengers Costumes</h1>
            <h1 className="font-extralight">Waffles the Cat in Avengers Costumes</h1>
            <h1 className="mt-2 text-xs text-gray-500">April 3, 2024</h1>
          </div>
        </div>

      </section>


      <hr />

      <section className="md:h-40 mt-10 bg-orange-200 md:mx-20 w-[90%] mx-auto px-3">
        <h1 className="text-4xl font-semibold text-center pt-10">Disclaimer</h1>
        <p className="md:text-xl text-center text-xs pb-4 font-extralight md:px-40 mt-3">This not associated with original Waffle Cat. Website and meme created by fans and do not promise financial gain.</p>
      </section>


    </div>
  );
}

export default App;
